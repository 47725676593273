/**
 * VAYES JS Extensions
 */

/**
 * LazyLoad Images
 */
(function(e){e.fn.deferredImages=function(k,d){function c(){g=b.filter(function(){var a=e(this);if(!a.is(":hidden")){var b=f.scrollTop(),d=b+f.height(),c=a.offset().top;return c+a.height()>=b-h&&c<=d+h}}).trigger("unveil");b=b.not(g)}var f=e(window),h=k||0,l=1<window.devicePixelRatio?"data-src-retina":"data-src",b=this,g;this.one("unveil",function(){var a=this.getAttribute(l);if(a=a||this.getAttribute("data-src"))this.setAttribute("src",a),"function"===typeof d&&d.call(this)});f.on("scroll.unveil resize.unveil lookup.unveil",
c);c();return this}})(window.jQuery||window.Zepto);


/**
 * exists : Check if selected elements exists
 * Usage : $('#required').exists();
 */
(function($){$.fn.exists=function(){return this.length!==0;}})(jQuery);


/**
 * Convert form inputs to object by serializing.
 * Usage : $('#formID').serializeObject();
 */
(function($){
  $.fn.serializeObject=function(){var o={};var a=this.serializeArray();$.each(a,function(){if(o[this.name]){if(!o[this.name].push)o[this.name]=[o[this.name]];o[this.name].push(this.value||"")}else o[this.name]=this.value||""});return o};
})(jQuery);



/**
 * Return a random value from an array
 * @return mixed
 *
 * Usage:
 * var themes = ['austin','berlin','chicago','great-plains','honolulu','london','new-york-city','rocky-mountains','san-francisco','seattle','tahoe'];
 * var selectedTheme = themes.randomElement(); // Returns "london" e.g.
 */
Array.prototype.randomElement = function () {
    return this[Math.floor(Math.random() * this.length)]
}

/**
 * Preloads images in given src array
 * @param  array   array
 * @param  callback fn
 * @return DOM
 *
 * Usage:
 * $.preload(igm, function(perc, done) {
 *   // console.log('Loading... ' + parseInt(perc) + '%');
 *   if(done) {
 *     $('.vws-drawer-header').css('background-image','url('+nowImgPath+nowImgSet+'--'+timeOfDay+'.webp'+')').css('opacity',1);
 *   }
 * });
 */
$.preload = function (array, fn) {
    var len = array.length, i = 0;
    return $.each(array, function () {
        var tmp = new Image, imgPath = this;
        if (fn) tmp.onload = function () {
            fn.call(self, 100 * ++i / len, i === len);
        };
        tmp.src = imgPath;
    });
};


/**
 * Toggles Text
 * @param  {string} a
 * @param  {string} b
 * @return {el}
 */
$.fn.extend({
    toggleText: function(a, b) {
        var that = this;
        if (that.text() != a && that.text() != b) {
            that.text(a);
        } else
        if (that.text() == a) {
            that.text(b);
        } else
        if (that.text() == b) {
            that.text(a);
        }
        return this;
    }
});

$.fn.extend({
    toggleHtml: function(a, b) {
        var that = this;
        if (that.html() != a && that.html() != b) {
            that.html(a);
        } else
        if (that.html() == a) {
            that.html(b);
        } else
        if (that.html() == b) {
            that.html(a);
        }
        return this;
    }
});


/**
 * in_array()
 *
 * Checks if the given value exists in the arrays
 *
 * @param  {string}   needle
 * @param  {mixed}    haystack
 * @param  {boolean}  argStrict
 * @return {boolean}
 */
function in_array(needle,haystack,argStrict){
  // src => http://phpjs.org/functions/in_array)
  // example 1: in_array('van', ['Kevin', 'van', 'Zonneveld']); returns 1: true
  // example 2: in_array('vlado', {0: 'Kevin', vlado: 'van', 1: 'Zonneveld'}); returns 2: false
  // example 3: in_array(1, ['1', '2', '3']); returns 3: true
  // example 3: in_array(1, ['1', '2', '3'], false); returns 3: true
  // example 4: in_array(1, ['1', '2', '3'], true); returns 4: false
  var key='',strict = !! argStrict;
  if(strict){for(key in haystack){if(haystack[key]===needle){return true;}}}
  else{for(key in haystack){if(haystack[key]==needle){return true;}}}
  return false;
}

/**
 * Init EYS Global Object
 * @type Object
 */
var EYS = {
    URI: {},
    XHR: {},
    Cart: {},
    Messenger: {},
    BaseURL: function(uri) {
        if(typeof uri === 'undefined') {
            return EYS.URI.getProtocol()+'//'+EYS.URI.getHostName()+EYS.URI.getPortNumber()+'/';
        } else {
            return EYS.URI.getProtocol()+'//'+EYS.URI.getHostName()+EYS.URI.getPortNumber()+'/'+uri;
        }

    },
    redirect: function(uri) {
        var uri = (typeof uri === 'undefined') ? '' : uri;
        return window.location = EYS.BaseURL(uri);
    },
};

/**
 * Color Definitions
 */
var colours = {"success":"#2e7d32","error":"#c62828","danger":"#c62828","warning":"#d84315","info":"#0277bd","primary":"#212121","secondary":"#37474f"};

/**
 * EYS.URI COMPONENTS
 */
EYS.URI.getProtocol       = function() {return window.location.protocol}
EYS.URI.getHostName       = function() {return window.location.hostname}
EYS.URI.getPortNumber     = function() {return (window.location.port ? ':' + window.location.port: '')}
EYS.URI.getUriString      = function() {return window.location.pathname}
EYS.URI.getUriSegments    = function() {return window.location.pathname.split( '/' );}
EYS.URI.getQueryString    = function () {qS={};window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi,function(str,key,value){qS[key]=value});if(typeof qS.q!=='undefined'){qS.q=decodeURIComponent(qS.q)};return qS}
EYS.URI.uriFormArray      = function(uriSegmentsArray) {return uriSegmentsArray.join('/')}
EYS.URI.uriWOLastSegment  = function() {var uS=EYS.URI.getUriSegments();uS.splice(-1,1);return uS}
EYS.URI.segment           = function(indice) {var segments=this.getUriSegments();return (typeof segments[indice]==='undefined')?false:segments[indice]}
EYS.URI.go                = function(uri,params,isLocal) {var params=(typeof params==="undefined")?false:params;var isLocal=(typeof isLocal==="undefined")?true:isLocal;params=$.param(params);if(isLocal==true){if(uri){if(params){window.location.href=this.getProtocol()+"//"+this.getHostName()+uri+"?"+params}else{window.location.href=this.getProtocol()+"//"+this.getHostName()+uri}}else{if(params){window.location.href=this.getProtocol()+"//"+this.getHostName()+this.getUriString()+"?"+params}else{window.location.href=this.getProtocol()+"//"+this.getHostName()+this.getUriString()}}}else{if(params){window.location.href=uri+"?"+params}else{window.location.href=uri}}};


/**
 * EYS XHR COMPONENTS
 * "sect" is CSRF Key, and sect is CSRF Token
 */
EYS.XHR.call=function(uri,data,callback_before,callback_done,callback_fail){
    var post,api_call;if(!("FormData" in window)){alert("Your browser does not support the new technologies. To proceed, please use one of the modern browsers.");return false;alert("Tarayıcınız modern teknolojileri desteklemiyor. Devam etmek için lütfen modern bir tarayıcı kullanın.");return false}
    if(typeof uri==="undefined"){return false}if(typeof data==="undefined"){return false}
    if(typeof data.get === 'function') {
        post=data;
        if(!post.has("ctu_token")){post.append("ctu_token",sect)}
    } else {
        post=new FormData();
        if(!data.hasOwnProperty("ctu_token")){post.append("ctu_token",sect)}
        for(key in data){post.append(key,data[key])}
    }
    var api_call=$.ajax({method:"POST",url:uri,contentType:false,processData:false,data:post,beforeSend:function(xhr){if(typeof callback_before==="function"){callback_before(xhr)}},success:function(response){},error:function(response){}});
    api_call.done(function(response){if(typeof callback_done==="function"){callback_done(response)}else{if(response.status=="success"){EYS.success(response.message)}else{EYS.error(response.message)}}});
    api_call.fail(function(response){if(typeof callback_fail==="function"){callback_fail(response)}else{EYS.error(response.statusText)}})
};

EYS.success=function(message, ttl){
    var timeToLive = (typeof ttl === 'undefined') ? 6000 : ttl;
    return Snackbar.show({
        text:message,
        backgroundColor:colours.success,
        actionText:"<i class=\"fa fa-check fa-lg fa-fw\"></i>",
        duration:timeToLive
    })
}
EYS.error=function(message, ttl){
    var timeToLive = (typeof ttl === 'undefined') ? 10000 : ttl;
    return Snackbar.show({
        text:message,
        backgroundColor:colours.error,
        actionText:"<i class=\"fa fa-angle-down fa-lg fa-fw\"></i>",
        duration:timeToLive
    })
}
EYS.warning=function(message, ttl){
    var timeToLive = (typeof ttl === 'undefined') ? 10000 : ttl;
    return Snackbar.show({
        text:message,
        backgroundColor:colours.warning,
        actionText:"<i class=\"fa fa-angle-down fa-lg fa-fw\"></i>",
        duration:timeToLive
    })
}
EYS.info=function(message, ttl){
    var timeToLive = (typeof ttl === 'undefined') ? 10000 : ttl;
    return Snackbar.show({
        text:message,
        backgroundColor:colours.info,
        actionText:"<i class=\"fa fa-angle-down fa-lg fa-fw\"></i>",
        duration:timeToLive
    })
}

EYS.URI.segment = function (index) {
    var pathArray = window.location.pathname.split('/');// [0] is always ""
    return (typeof pathArray[parseInt(index)] === 'undefined') ? 'NaN' : pathArray[index];
}


EYS.Cart.addItem = function() {
  var post = {};
  post.var = $("a.product-size.selected").data("variant");
  post.pid = $(".action--add-to-cart").data("id");
  post.qty = parseInt($("#quantity").val());
  EYS.XHR.call(EYS.BaseURL('module/CartManagement/addItemAction'), post, null);
}

EYS.Cart.removeItem = function() {
  var post = {};
  post.var = $("a.product-size.selected").data("variant");
  post.pid = $(".action--add-to-cart").data("id");
  post.qty = parseInt($("#quantity").val());
  EYS.XHR.call(EYS.BaseURL('module/CartManagement/removeItemAction'), post, null);
}

EYS.Cart.countRows = function() {
    EYS.XHR.call(EYS.BaseURL('module/CartManagement/countCartRowsAction'), {}, null, EYS.Cart.updateCartRowsCount);
}

EYS.Cart.updateCartRowsCount = function(response) {
    if(response.status == 'success') {
        $('.jq-basket-count').html(response.message);
    }
}


$(function(){
    $('.devCacheClear').on('click', function(e){
        e.preventDefault();
        swal({
            title: "Emin misiniz?",
            text: "Bu işlem ile önbelleğiniz temizlenecektir!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Evet, Önbelleği Temizle",
            cancelButtonText: "Vazgeç",
            closeOnConfirm: false,
            closeOnCancel: false,
            showLoaderOnConfirm: true
        },
        function(isConfirm){
            if (isConfirm) {
                EYS.XHR.call(EYS.BaseURL('system/CacheManagement/xhrClearCacheFolder'), {},
                    null,
                    function(xhr){swal("İşleminiz tamamlandı!", xhr.message, xhr.status)},
                    function(xhr){swal("Bir hata oluştu!", xhr.statusText, "error")}
                );
            } else {
                swal("İşleminiz iptal edildi.", "Önbelleği temizlemekten vazgeçtiniz", "info");
            }
        });
    });
});

$(function(){
    $('.devMediaClear').on('click', function(e){
        e.preventDefault();
        swal({
            title: "DİKKAT! Emin misiniz?",
            text: "Bu işlem ile kullanıcılarınızın yüklediği verileri sileceksiniz! Bu işlem geri alınamaz!!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Evet, Geliştirme Ortamındayım",
            cancelButtonText: "Vazgeç",
            closeOnConfirm: false,
            closeOnCancel: false,
            showLoaderOnConfirm: true
        },
        function(isConfirm){
            if (isConfirm) {
                EYS.XHR.call(EYS.BaseURL('system/CacheManagement/xhrClearMediaFolder'), {},
                    null,
                    function(xhr){swal("İşleminiz tamamlandı!", xhr.message, xhr.status)},
                    function(xhr){swal("Bir hata oluştu!", xhr.statusText, "error")}
                );
            } else {
                swal("İşleminiz iptal edildi.", "Media klasörünü silmekten vazgeçtiniz", "info");
            }
        });
    });
});
